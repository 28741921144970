<template>
      <div id="formWrap">
            <router-view></router-view>
      </div>

</template>

<script>
  export default {
    name: "login",
  }
</script>

<style>
.inputBlock { position: relative; margin: 2vw 0; }
.inputBlock>input { background-color: unset; border: 2px solid rgba(67, 172, 52, 0.3); box-sizing: border-box; border-radius: 8px; outline: 0; padding: 3.82vw 2.35vw; font-family: 'Ubuntu'; font-size: 3.24vw; width: 100% }
.inputBlock>label { opacity: 0; position: absolute; left: 5vw; top: 1vw; color: rgba(51, 51, 51, 0.54); pointer-events: none; transition: ease-out 0.3s; font-size: 3.24vw }
.inputBlock .showLabel { opacity: 1 }
.contentFooter { display: flex; align-items: center; justify-content: space-between; border-top: none; padding: 0 0 4.41vw 0;  }
.stxt { font-size: 2.06vw; text-align: center; margin: 2.06vw 0 4.12vw }
#formWrap {display: flex;justify-content: center}
#formWrap .contentBlock .title { border: none }
#formWrap .contentBlock .title>h3 { font-size: 6.18vw; font-weight: normal }
#formWrap .contentBlock .body { margin-bottom: 4.12vw }
#formWrap .contentBlock button { line-height: normal }
#loginBlock button { padding: 3.82vw 11.18vw }
#loginBlock .greenBanner>img { width: 100% }
#navLoginWrap { font-family: 'Ubuntu'; letter-spacing: normal }
#navLoginWrap a:first-child { margin-bottom: 2.65vw }
#navLoginWrap a:last-child { margin-bottom: 0 }
#recoveryBlock button { padding: 3.53vw 4.12vw }
#recoveryBlock .contentFooter { align-items: flex-end }
#loginSection .contentFooter .linkColor { color: #43AC34; text-decoration-line: underline; font-size: 4.12vw; border-bottom: none }
@media screen and (min-width: 760px) {

}
@media screen and (min-width: 1200px){
  .inputBlock { margin: 0 0 12px; }
  .inputBlock>input {border: 2px solid rgba(67, 172, 52, 0.3); box-sizing: border-box; border-radius: 10px; outline: 0; padding: 17px 11px; font-family: 'Roboto'; font-size: 14px; width: 100% }
  .inputBlock>label { left: 11px; top: 2px; font-size: 14px }
  .contentFooter { display: flex; align-items: center; justify-content: space-between; border-top: none; padding: 0 0 19px 0;  }
  .stxt { font-size: 14px; text-align: center; margin: 16px 0 17px }
  #formWrap {display: flex;justify-content: center}
  #formWrap .contentBlock { margin-right: 54px }
  #formWrap .contentBlock .title { border: none; padding: 0 }
  #formWrap .contentBlock .title>h3 { font-size: 27px }
  #formWrap .contentBlock .body { margin-bottom: 18px }
  #formWrap .contentBlock button { line-height: normal }


  #loginBlock { display: flex; align-items: center; justify-content: center; }
  #loginBlock .greenBanner { width: 368px }
  #loginBlock .contentBlock { width: 368px }
  #loginBlock button { padding: 17px 48px }
  #loginBlock .greenBanner>img { width: 100% }
  #navLoginWrap a:first-child { margin-bottom: 12px }
  #navLoginWrap a:last-child { margin-bottom: 0 }
  #recoveryBlock .contentBlock { margin-right: 0; width: 532px }
  #recoveryBlock { display: flex; justify-content: center; }
  #recoveryBlock .contentFooter { align-items: flex-end }
  #recoveryBlock button { padding: 17px 48px }
  #loginSection { display: flex; align-items: center; justify-content: center; }
  #loginSection .contentFooter .linkColor { font-size: 18px }
  #loginSection .contentBlock { margin-right: 0; width: 532px }
  #signinForm .contentBlock { margin-right: 0; width: 532px }

}
</style>
